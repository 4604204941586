.main {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    gap: 60px;
    padding-bottom: 20px;
    width: 100%;
    opacity: .7;
}

.content {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    gap: 20px;
}

.marketing {
    flex: 1 1;
    max-width: 410px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--container {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    &--content {
        width: 410px;
        height: 102px;
    }

    &--badges {
        display: flex;
        flex-direction: row;
        gap: 24px;
    }

    &--badge {
        flex: 1;
        height: 68px;
    }
}

.form {
    width: 450px;
    height: 700px;
}

.badges {
    display: flex;
    gap: 16px;
    opacity: .7;

    &--item {
        width: 168px;
        height: 49px;
        border-radius: 30px;
    }
}

.footer {
    opacity: .7;
    width: 80%;
    height: 18px;

    margin-top: 15px;
}