@import '~@ui/styles/variables';
@import '~@ui/styles/tools';

@mixin medium-breakpoint-up {
    @media screen and (min-width: 1651px) {
        @content;
    }
}

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1250px) {
        @content;
    }
}

@mixin xsmall-breakpoint {
    @media screen and (max-width: 950px) {
        @content;
    }
}

.desktop-view {
    height: min(calc(100vh - 92px), 988px);

    .container {
        height: min(calc(100vh - 92px), 988px);
        padding-bottom: 370px;
    }
}

.container {
    padding: 4% 0 4% 8%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;

    // Used for .mobile-button-wrapper with absolute positioning
    position: relative;
}

.main-info {
    width: fit-content;
    z-index: 2;
}

.title {
    font-size: 60px;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 24px;
    max-width: 25ch;

    b,
    em,
    strong {
        font-weight: 500;
        color: $color-primary;
    }

    &--skeleton {
        height: calc(60px * 1.2 * 2);
    }
}

.main-info__description {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 64px;

    &--skeleton {
        height: calc(20px * 1.2);
        width: 450px;
    }
}

.achievements--skeleton {
    width: 100%;
    min-width: 900px;
    display: flex;
    gap: 30px;

    .achievement {
        flex: 1;
        height: 40px;
    }
}
.statistics-skeleton {
    position: absolute;
    bottom: 24px;

    display: flex;
    gap: 30px;
    width: 900px;

    &--item {
        height: 180px;
    }
}

@include medium-breakpoint {
    .desktop-view {
        height: auto;

        .container {
            height: auto;
        }
    }

    .container {
        padding-bottom: 0;
    }

    .title {
        font-size: 56px;

        &--skeleton {
            width: 300px;
            height: calc(56px * 1.2 * 2);
        }
    }

    .main-info__description {
        font-size: 18px;

        &--skeleton {
            height: calc(18px * 1.2);
        }
    }
}


@include small-breakpoint {
    .desktop-view {
        .container {
            padding-bottom: 270px;
        }
    }

    .main-info:not(.main-info--mobile) {
        padding-top: initial;
    }

    .title {
        font-size: 40px;

        &--skeleton {
            width: 606px;
            height: calc(40px * 1.2 * 2);
        }
    }

    .main-info__description {
        font-size: 16px;
        margin-bottom: 20px;

        &--skeleton {
            width: 606px;
            height: calc(16px * 1.2);
        }
    }

    .achievements--skeleton {
        padding: 0;
        display: grid;
        grid-template-columns: 136px 210px;
        grid-template-rows: repeat(2, 1fr);
        gap: 30px;
    }
}

@include media-tablet {
    .desktop-view {
        max-height: unset;
        .container {
            padding: 4% 16px 225px 16px;
        }
    }

    .title {
        margin-bottom: 20px;
        max-width: 15ch;

        &--skeleton {
            width: 300px;
            height: calc(40px * 1.2 * 2);
        }
    }

    .main-info__description {
        max-width: 30ch;

        &--skeleton {
            height: calc(20px * 1.2);
        }
    }

    .achievements--skeleton {
        justify-content: flex-start;
        margin-top: 24px;
    }
}

@include xsmall-breakpoint {
    .main-info {
        padding-bottom: 36px;
    }

    .title {
        font-size: 36px;
        margin-bottom: 16px;

        &--skeleton {
            width: 300px;
            height: calc(36px * 1.2 * 2);
        }
    }

    .main-info__description {
        font-size: 14px;
        margin-bottom: 16px;

        &--skeleton {
            height: calc(14px * 1.2);
        }
    }

    .achievements--skeleton {
        gap: 10px;
    }

    .statistics-skeleton {
        height: 190px;
        width: 100%;
        padding-right: 30px;
        gap: 8px;
    }
}

@include media-tablet-portrait {
    .achievements--skeleton {
        gap: 10px;
    }
}

@include media-mobile {
    .desktop-view {
        .container {
            flex-direction: column;
            padding-bottom: 155px;
            padding-right: 16px;
            overflow: hidden;
        }
    }

    .main-info {
        margin-bottom: 0;
        width: 100%;
        padding-bottom: unset;
    }

    .title {
        margin-bottom: 12px;

        &--skeleton {
            width: 300px;
            height: calc(36px * 1.2 *2);
        }
    }

    .main-info__subsection {
        max-width: 100%;
    }

    .main-info__description {
        margin-bottom: 16px;

        &--skeleton {
            height: calc(16px * 1.2 * 3);
        }
    }

    .main-info__button-wrapper {
        margin: 0 auto 32px;
        max-width: 250px;
    }

    .achievements--skeleton {
        grid-template-columns: 100px 200px;
        gap: 7px 20px;
    }

    .statistics-skeleton {
        display: none;
    }

    .achievement {
        &:nth-child(3) {
            max-width: 200px;
        }
    }
}

@media only screen and (max-width: 335px) {
    .achievements--skeleton {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .achievement {
        &:nth-child(n) {
            grid-column: 1 / 2;
        }

        &:nth-child(1) {
            grid-row: 1 / 2;
        }

        &:nth-child(2) {
            grid-row: 2 / 3;
        }

        &:nth-child(3) {
            grid-row: 3 / 4;
        }

        &:nth-child(4) {
            grid-row: 4 / 5;
        }
    }
}

@include media-mobile-up {
    .mobile-view {
        display: none;
    }
}

@include media-tablet-portrait-up {
    .desktop-view {
        .footer {
            .achievements--skeleton {
                display: none;
            }
        }
    }
}



