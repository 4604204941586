.container {
    background-color: #f0f0f0;
    animation: pulse 1.5s infinite ease-in-out;
    width: 100%;
    border-radius: 12px;
}

@keyframes pulse {
    0% {
        background-color: #f0f0f0;
    }
    50% {
        background-color: #e0e0e0;
    }
    100% {
        background-color: #f0f0f0;
    }
}
