@import "src/styles/variables";
@import "~@ui/styles/tools";

@mixin breakpoint-1450 {
    @media screen and (max-width: 1450px) {
        @content;
    }
}

.post {
    background-color: $color-page-background !important;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    row-gap: 40px;


    @include breakpoint-1450 {
        grid-template-columns: unset;
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    &--skeleton {
        width: 100%;
        height: 550px;
    }
}

.post__wrapper {
    grid-column: 1 / 9;
    position: relative;
    display: grid;
    column-gap: 20px;
    grid-template-columns: 2fr 5fr 1fr;

    @include breakpoint-1450 {
        margin-top: initial;
        display: flex;
    }

    &_skeleton {
        width: 100%;
        height: 550px;
    }
}

.post__container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.post__content {
    margin-bottom: 40px;
}

.post__path {
    grid-column: 3 / 8;
    width: 100%;
    max-width: 800px;
    margin: 20px auto 0;
    color: $color-grey--dark;
    font-weight: 600;
    line-height: 1.4;
    font-size: 14px;

    display: flex;
    align-items: center;
    gap: 8px;

    @include breakpoint-1450 {
        grid-column: unset;
        margin: 0 auto;
    }

    @include media-mobile {
        font-size: 12px;
    }

    &_skeleton {
        height: 20px;
        width: 60%;
    }
}

.post__path-link, .post__path-link:visited {
    color: $color-grey--dark;
    text-decoration: underline;
    white-space: nowrap;
}

.post__path-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.post__image-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 800px;
    aspect-ratio: 16/9;
    margin-bottom: 40px;

    img {
        object-fit: cover;
    }

    @include media-tablet {   
        margin-left: auto;
        margin-right: auto;
        width: 80%;
    }

    &--skeleton {
        width: 100%;
        height: 450px;
    }
}

.post__image {
    border-radius: 12px;
}

.post__title {
    font-size: 48px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 1px;
    margin-bottom: 32px;

    @include media-tablet {
        font-size: 44px;
        line-height: 1.2;
    }

    @include media-mobile {
        font-size: 38px;
    }

    &--skeleton {
        width: 100%;
        height: 50px;

        @include media-tablet {
            font-size: 30px;
            line-height: 1.2;
            height: calc(30px * 1.2);
        }

        @include media-mobile {
            font-size: 20px;
            line-height: 22px;
            height: 22px;
        }
    }
}

.post__footer {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;

    @include media-tablet-portrait {
        flex-direction: column;
        align-items: flex-start;
    }
}

.post__tags, .post__tags, .post__date {
    color: $color-grey--dark;
    margin-bottom: 0;
}

.post__tags-container {
    display: flex;
    gap: 20px;
    align-items: center;

    @include media-tablet-portrait {
        flex-direction: column;
        align-items: flex-start;
    }
}

.post__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    word-spacing: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;

    & a {
        cursor: pointer;
        
        &:not(:hover):not(:active) {
            color: $color-grey--dark;
        }
    }
}

.post__date {
    font-weight: 700;
    white-space: nowrap;
    font-size: 14px;
    line-height: 22px;
}

.social-media {
    top: 100px;
    position: sticky;
    display: flex;
    justify-self: flex-end;

    @include breakpoint-1450 {
        display: none;
    }
    
    &--skeleton {
        width: 106px !important;
        height: 226px;

        @include breakpoint-1450 {
            display: none;
        }
    }
}

.social-media--horizontal {
    display: none;
    gap: 10px;

    @include breakpoint-1450 {
        display: flex;
    }
}

.navigation--mobile-skeleton {
    display: none;
    width: 100%;
    height: 62px;

    @include breakpoint-1450 {
        display: block;
    }
}

.navigation--desktop-skeleton {
    width: 100%;
    height: 500px;

    @include breakpoint-1450 {
        display: none;
    }
}