.container {
    grid-area: content;

    .content {
        h1, h2, h3 {
            margin-bottom: 25px;
        }

        h3 {
            font-size: 24px;
        }

        p {
            font-size: 20px;
        }
    }

    &--skeleton {
        width: 100%;
        height: 350px;
    }
}

.title {
    color: #1E2021;
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    margin-bottom: 32px;
}

.no-visible {
    opacity: 0;
}
