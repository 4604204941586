@import "@frontend/jetlend-web-ui/src/styles/tools";

.skeleton {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  opacity: .7;

  @include media-desktop-md {
    gap: 12px;
  }

  @include media-tablet {
    align-items: center;
  }

  @include media-mobile {
    width: 100%;
  }

  &--steps {
    width: 158px;
    height: 12px;
    margin-bottom: 10px;
    margin-right: auto;
  }

  &--form {
    width: 450px;
    height: 570px;

    @include media-tablet {
      width: 450px;
      height: 500px;
    }

    @include media-mobile {
      width: 100%;
      height: 500px;
    }

    &-small {
        width: 450px;
        height: 305px;

        @include media-mobile {
            width: 100%;
        }
    }
  }
}
